





































































import axios from "axios";
import marked from "marked";
import Vue from "vue";
let rendererMD = new marked.Renderer();
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});
rendererMD.heading = function (text, level) {
  return `<h${level} style="margin-bottom:20px;margin-top:20px">${text}</h${level}>`;
};
rendererMD.link = function (href, title, text) {
  return `<a style="color:#4183c4;text-decoration: none;" href=${href}>${text}</a>`;
};
export default Vue.extend({
  data: () => ({
    serveraddr: Vue.prototype.serverAddr + "/",
    imagelist: [] as any,
    rules: [(value: any) => !!value || "Required"],
    name: "",
    link: "",
    date: "",
    content: "",
    processedContent: "",
    file: {} as any,
    type: "",
    group: "",
    groups: [
      "默认方向",
      "语音前端",
      "语音识别",
      "语音合成",
      "声纹识别",
      "多模态交互",
    ],
    types: ["news", "recommend", "notice"],
    picStart: "<img src=\"localhost/",
    picEnd: "\" width=100%/>"
  }),
  computed: {
    compiledMarkdown: function () {
      return marked(this.processedContent);
    },
  },
  mounted: function () {
    console.log(this.$route.params.id);
    const id = this.$route.params.id;
    if (id != "!") {
      console.log("getting " + id);
      let article = axios
        .post(Vue.prototype.serverAddr + "/api/get-item-by-id", {
          collection: "article",
          _id: id,
        })
        .then((response) => {
          let data: [] = response.data as any;
          if (data.length > 0) {
            const item = (data as any)[0];
            this.name = item.name;
            this.link = item.link != undefined ? item.link : "";
            this.date = item.date != undefined ? (new Date(item.date)).toISOString().split('T')[0] : "";
            this.content = item.content != undefined ? item.content : "";
            this.processedContent = this.content.replace(
              /localhost/g,
              Vue.prototype.serverAddr
            );
            this.imagelist = item.imagelist != undefined ? item.imagelist : [];
            this.group = item.group;
            this.type = item.type;
          }
        });
    } else {
        if (this.date == "") {
          const curdate = new Date()
          this.date = curdate.toLocaleDateString();
          this.date = (this.date as any).replace(/\//g, '-')
        }
    }
  },
  watch: {},
  methods: {
    deletePic: function(image: any) {
      this.imagelist.splice(this.imagelist.indexOf(image), 1)
    },
    update: function (e: any) {
      this.content = e;
      this.processedContent = this.content.replace(
        /localhost/g,
        Vue.prototype.serverAddr
      );
    },
    submit: function () {
      let method = "";
      if (this.$route.params.id == "!") {
        method = "post";
      } else {
        method = "update";
      }
      console.log("post in article");
      let obj = {
          _id: this.$route.params.id,
          collection: "article",
          name: this.name,
          link: this.link,
          date: new Date(this.date),
          content: this.content,
          imagelist: this.imagelist,
          type: this.type,
          group: this.groups.indexOf(this.group),
        }
        console.log("obj", typeof(obj.date))
      axios
        .post(Vue.prototype.serverAddr + "/api/" + method + "-item", obj)
        .then((response) => {
          this.$router.back();
        });
    },
    cancel: function () {
      this.$router.back();
    },
    getFile(file: any) {
      this.file = file;
    },
    uploadFile() {
      const URL = Vue.prototype.serverAddr + "/api/upload";

      let data = new FormData();
      data.append("name", "my-picture");
      data.append("file", this.file);

      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios.post(URL, data, config as any).then((response) => {
        console.log("image upload response > ", response);
        if (response.status == 200) {
          this.imagelist.push(this.file.name);
        }
      });
    },
  },
});
